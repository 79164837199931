function isNull(data) {
  if (data == null || data == undefined || data == "") {
    return true
  } else {
    return false
  }
}

//檢查文字是否為Blank
function isBlank(str) {
  if (typeof str === 'string') {
    return (!str || /^\s*$/.test(str));
  }
}

//檢查文字是否為數字
function isNumeric(str) {
  if (typeof str != "string") {
    return false
  }
  return !isNaN(str) && !isNaN(parseFloat(str))
}

//檢查密碼格式(alphanumeric)是否正確（可空值）
function checkPword(pword) {
  let pwordReg = new RegExp(
    "^.*(?=.{8,})(?=.*\\d)(?=.*[A-Za-z]).*$"
  );
  if (!pword || pwordReg.test(pword)) return true;
  //if(pwordReg.test(pword)) return true;
  else return false;
}

//檢查信箱格式是否正確（可空值）
function checkEmail(contactEmail) {
  // 檢查規則是否符合email
  var reg = new RegExp(
    "^[_a-z0-9-]+([.][_a-z0-9-]+)*@[a-z0-9-]+([.][a-z0-9-]+)*$"
  );
  if (!contactEmail || reg.test(contactEmail)) return true;
  else return false;
}

//移除手機號碼之間空白 (可空值)
function replaceCellPhoneSpace(cellphone) {
  if (!isNull(cellphone)) {
    return cellphone.replace(/\s+|&nbsp;/ig, '');
  }
  return cellphone;
}

//檢查 身分證規則
function checkSin(idStr) {
  // 依照字母的編號排列，存入陣列備用。
  var letters = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "M",
    "N",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "X",
    "Y",
    "W",
    "Z",
    "I",
    "O"
  ];
  // 儲存各個乘數
  var multiply = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1];

  var nums = new Array(2);
  var firstChar;
  var firstNum;
  var lastNum;
  var total = 0;
  // 撰寫「正規表達式」。第一個字為英文字母，
  // 第二個字為1或2，後面跟著8個數字，不分大小寫。
  var regExpID = /^[a-z](1|2)\d{8}$/i;
  // 使用「正規表達式」檢驗格式
  if (idStr.search(regExpID) == -1) {
    // 基本格式錯誤
    // alert("身分證號碼寫錯誤！")
    return false;
  } else {
    // 取出第一個字元和最後一個數字。
    firstChar = idStr.charAt(0).toUpperCase();
    lastNum = parseInt(idStr.charAt(9), 10); // {{ string | number : fractionsize}};fractionsize: A number, specifying the number of decimals.
  }

  // 找出第一個字母對應的數字，並轉換成兩位數數字。
  for (var i = 0; i < 26; i++) {
    if (firstChar == letters[i]) {
      firstNum = i + 10;
      nums[0] = Math.floor(firstNum / 10);
      nums[1] = firstNum - nums[0] * 10;
      break;
    }
  }

  // 執行加總計算
  for (var i = 0; i < multiply.length; i++) {
    if (i < 2) {
      total += nums[i] * multiply[i];
    } else {
      total += parseInt(idStr.charAt(i - 1)) * multiply[i];
    }
  }

  //規則一餘數為零，且檢查碼需為零
  if (lastNum == 0 && total % 10 != lastNum) {
    // alert("身分證號碼寫錯誤！")
    return false;
  }
  //規則二餘數與檢查碼需相符
  if (lastNum != 0 && 10 - (total % 10) != lastNum) {
    // alert("身分證號碼寫錯誤！")
    return false;
  }
  return true;
}

//檢查 公司統編規則
function checkCompanySin(feinStr) {
  // 儲存各個乘數
  let multiply = [1, 2, 1, 2, 1, 2, 4, 1];

  let caleSum = 0;

  // 撰寫「正規表達式」。營利事業統一編號，長度:共八位，，全部為數字型態
  let regExpID = /^\d{8}$/;
  // 使用「正規表達式」檢驗格式
  if (feinStr.search(regExpID) == -1) {
    // 基本格式錯誤
    console.error("統編錯誤，要有 8 個 0-9 數字組合");
    return false;
  }

  let cnum = feinStr.split("");
  for (let i = 0; i < multiply.length; i++) {
    caleSum += calcCompanySinWeigh(parseInt(cnum[i]) * multiply[i]);
  }
  if (caleSum % 10 == 0) {
    console.log("統一編號:" + feinStr + " 正確!");
    return true;
  }
  else if (cnum[6] == '7' && (caleSum + 1) % 10 == 0) {
    console.log("統一編號:" + feinStr + " 正確!");
    return true;
  }
  else {
    console.error("統一編號:" + feinStr + " 錯誤!");
    return false;
  }

}

//計算 公司權重值
function calcCompanySinWeigh(n) {
  if (n > 9) {
    let s = n + "";
    let n1 = parseInt(s.substring(0, 1)) * 1;
    let n2 = parseInt(s.substring(1, 2)) * 1;
    n = n1 + n2;
  }
  return n;
}

//JS判断字符串是否为json数据
function isJSON(str) {
  if (typeof str == 'string') {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      // console.log(e);
      return false;
    }
  }
  console.log('It is not a string!');
}

//移除obj中為null 或 空字串 或 undefined 的property
function removeNullAndEmptyStringFromObj(obj) {
  let newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (!isNull(obj[prop])) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export default {
  isNull,
  isBlank,
  isNumeric,
  checkPword,
  checkEmail,
  checkSin,
  replaceCellPhoneSpace,
  checkCompanySin,
  isJSON,
  removeNullAndEmptyStringFromObj,
}
