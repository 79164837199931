export default [
  {
    area: "台灣",
    name: "Asia/Taipei",
    describe: "台灣標準時間",
    utc: "UTC+8",
  },
  {
    area: "韓國",
    name: "Asia/Seoul",
    describe: "韓國標準時間",
    utc: "UTC+9",
  },
  {
    area: "中國",
    name: "Asia/Shanghai",
    describe: "中國標準時間",
    utc: "UTC+8",
  },
  {
    area: "香港",
    name: "Asia/Hong_Kong",
    describe: "香港標準時間",
    utc: "UTC+8",
  },
  {
    area: "日本",
    name: "Asia/Tokyo",
    describe: "日本標準時間",
    utc: "UTC+9",
  },
  {
    area: "新加坡",
    name: "Asia/Singapore",
    describe: "新加坡標準時間",
    utc: "UTC+8",
  },
  {
    area: "美國舊金山",
    name: "America/Los_Angeles",
    describe: "太平洋標準時間",
    utc: "UTC-8",
  },
  {
    area: "美國紐約",
    name: "America/New_York",
    describe: "東部標準時間",
    utc: "UTC-5",
  },
  {
    area: "加拿大愛德華王子島",
    name: "America/Moncton",
    describe: "大西洋標準時間",
    utc: "UTC-4",
  },
];
