import Vue from 'vue'
import store from './store'
import Utils from './utils'
const whiteList = [] // 不重定向白名单
Vue.router.beforeEach((to, from, next) => {

  // 紀錄 上一頁 Url path
  sessionStorage.setItem("PreviousPageUrl", from.path);
  
  // next()
  // return
  // 假設有token
  if (Utils.Auth.isAuth()) {
    // next()
    // to.matched.some(m => console.log(Utils.Auth.hasRole(m.meta.role)))
    console.log("beforeEach:"+ Utils.Auth.hasRole(to.meta.role))
    if(Utils.Auth.hasRole(to.meta.role)){
      console.log('ok頁面')
      next()
    } else {
      console.log('無權限，離開頁面')
      next({
        name: 'Dashboard'
      })
    }
    // // 而且目標是遊客葉面 就跳回首頁
    // if (to.matched.some(m => m.meta.guest)) {
    //   console.log('ok guest')
    //   next({
    //     name: 'Login'
    //   })
    // } else {
    //   // 否則檢查 現在有沒有用登入過
    //   if (!Utils.Auth.isAuth()) {
    //     // 沒有的話 就重新驗證
    //     store.dispatch('auth/status').then(res => {
    //       next()
    //     })
    //   } else {
    //     console.log('ok isAuth')
    //     next()
    //   }
    // }
  } else {
    if (to.matched.some(m => m.meta.auth)) {
      console.log('無登入，離開頁面')
      next({
        name: 'Login'
      })
    } else {
      next()
    }
  }
})
