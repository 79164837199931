import Auth from './auth'
import Check from './check'
import Country from './country'
import TimeZone from './timeZone'

export default {
    Auth,
    Check,
    Country,
    TimeZone
}