// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox, 
// the second argument is the SVG content stripped of SVG tags
export const logo = ['269 27', `
  <g>
  <svg width="269" height="27" viewBox="0 0 269 27" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0)">
  <g clip-path="url(#clip1)">
  <path d="M15.0108 26.9859H29.1817C29.1817 24.5033 27.1421 22.4936 24.6225 22.4936H23.8226C25.3957 20.4839 26.2622 18.0539 26.2622 15.4925C24.6092 15.4925 23.1561 16.5433 22.6362 18.0933C22.2229 19.3149 21.5297 20.4445 20.5699 21.3903C18.9568 22.9796 16.8372 23.8466 14.5975 23.8466C12.3579 23.8466 10.2249 22.9796 8.62521 21.3903C7.02548 19.8009 6.1323 17.7124 6.1323 15.5056C6.1323 13.2989 7.01215 11.1972 8.62521 9.62099C9.58505 8.67524 10.7315 7.97907 11.9713 7.58501C13.5444 7.07273 14.6109 5.64098 14.6109 4.01219C11.4781 3.99906 8.55856 5.19437 6.33226 7.37484C4.10597 9.55531 2.90618 12.4451 2.90618 15.4925C2.90618 18.0539 3.75937 20.4839 5.34576 22.4936H4.55923C2.03966 22.4936 0 24.5033 0 26.9859H14.1443C14.2909 26.9859 14.4242 26.9991 14.5709 26.9991C14.7175 26.9991 14.8642 26.9991 15.0108 26.9859Z" fill="url(#paint0_linear)"/>
  <path d="M19.1034 8.66304C19.9869 8.66304 20.7031 7.95734 20.7031 7.0868C20.7031 6.21627 19.9869 5.51056 19.1034 5.51056C18.2199 5.51056 17.5037 6.21627 17.5037 7.0868C17.5037 7.95734 18.2199 8.66304 19.1034 8.66304Z" fill="url(#paint1_linear)"/>
  <path d="M23.316 12.7219C24.1995 12.7219 24.9158 12.0162 24.9158 11.1456C24.9158 10.2751 24.1995 9.5694 23.316 9.5694C22.4325 9.5694 21.7163 10.2751 21.7163 11.1456C21.7163 12.0162 22.4325 12.7219 23.316 12.7219Z" fill="url(#paint2_linear)"/>
  <path d="M23.8228 22.4946C23.5162 22.8886 23.183 23.2564 22.823 23.6111C20.7167 25.6865 17.9572 26.8818 15.011 26.9869H29.1686C29.1686 24.5043 27.129 22.4946 24.6094 22.4946H23.8228Z" fill="url(#paint3_linear)"/>
  <path d="M14.5977 27C14.731 27 14.8509 27 14.9843 26.9868C14.8509 27 14.731 27 14.5977 27Z" fill="url(#paint4_linear)"/>
  </g>
  <path d="M103.518 27V9.37018C104.624 9.37018 105.509 10.2739 105.509 11.3742V24.9829C105.509 26.0963 104.624 27 103.518 27Z" fill="#838383"/>
  <path d="M31.8237 27V9.37018C32.9301 9.37018 33.8152 10.2739 33.8152 11.3742V24.9829C33.8152 26.0963 32.9171 27 31.8237 27Z" fill="#838383"/>
  <path d="M132.023 27V13.627C133.13 13.627 134.015 14.5308 134.015 15.631V24.9829C134.028 26.0963 133.13 27 132.023 27Z" fill="#838383"/>
  <path d="M95.6557 26.9476C92.9093 26.8952 90.7485 24.5769 90.7485 21.8263V13.902C91.8419 13.902 92.7401 14.7927 92.7401 15.906V21.8525C92.7401 23.5028 94.0287 24.9043 95.6687 24.9436C97.3739 24.996 98.7666 23.6207 98.7666 21.918V15.906C98.7666 14.8058 99.6518 13.902 100.758 13.902V21.918C100.758 24.7209 98.4673 27 95.6557 26.9476Z" fill="#838383"/>
  <path d="M42.7835 26.9738C41.6901 26.9738 40.792 26.0832 40.792 24.9698V19.4556C40.792 17.5302 39.2951 15.9191 37.3817 15.8799C35.4162 15.8406 33.8152 17.4254 33.8152 19.3901H31.8237C31.8237 16.3252 34.3359 13.8235 37.3947 13.8759C40.4015 13.9152 42.7965 16.4562 42.7965 19.4818L42.7835 26.9738C42.7965 26.9738 42.7965 26.9738 42.7835 26.9738Z" fill="#838383"/>
  <path d="M74.5435 26.9738V19.5866C74.5435 16.4562 77.0686 13.9152 80.1795 13.9152C80.1795 14.9237 79.5938 15.8275 78.6827 16.2466C77.4201 16.8229 76.535 18.1065 76.535 19.5866V24.9698C76.535 26.0832 75.6498 26.9738 74.5435 26.9738Z" fill="#838383"/>
  <path d="M83.668 26.9738V15.2511C83.668 12.1207 86.1931 9.57971 89.304 9.57971C89.304 10.5883 88.7183 11.492 87.8071 11.9111C86.5446 12.4875 85.6594 13.7711 85.6594 15.2511V24.9698C85.6594 26.0831 84.7613 26.9738 83.668 26.9738Z" fill="#838383"/>
  <path d="M51.7647 26.6856C50.0075 26.6856 48.3675 26.0045 47.1309 24.7733C45.8944 23.529 45.1654 21.9442 45.0873 20.3069C44.9962 18.5911 45.621 16.967 46.8576 15.7357C48.0811 14.5045 49.7081 13.8496 51.4393 13.8889C53.1314 13.9282 54.7455 14.6355 55.969 15.8798C56.3595 16.2728 56.3595 16.9015 55.969 17.2944L53.574 19.7044C52.793 20.4903 51.5304 20.4903 50.7494 19.7044L53.7692 16.6657C53.0533 16.1942 52.2203 15.9191 51.3742 15.906C50.2028 15.8798 49.0834 16.3252 48.2503 17.1634C47.4173 18.0017 47.0007 19.0495 47.0658 20.2152C47.1179 21.3548 47.6385 22.4681 48.5237 23.3588C49.3827 24.2232 50.5152 24.6947 51.7387 24.6947C52.9622 24.6947 54.1077 24.2232 54.9537 23.3588C55.7087 22.5991 56.1643 21.5905 56.2684 20.5427C56.3595 19.5211 57.0754 18.6697 58.0646 18.4077C58.6634 20.6737 58.0126 23.1099 56.3725 24.7733C55.162 26.0045 53.5219 26.6856 51.7647 26.6856Z" fill="#838383"/>
  <path d="M66.2911 26.6856C64.5339 26.6856 62.8938 26.0045 61.6573 24.7733C60.4207 23.529 59.6918 21.9442 59.6137 20.3069C59.5226 18.5911 60.1474 16.967 61.3839 15.7357C62.6075 14.5045 64.2345 13.8496 65.9657 13.8889C67.6578 13.9282 69.2718 14.6355 70.4953 15.8798C70.8858 16.2728 70.8858 16.9015 70.4953 17.2944L66.6946 21.119C65.9136 20.3331 65.9136 19.0626 66.6946 18.2768L68.3086 16.6526C67.5927 16.1811 66.7597 15.906 65.9136 15.8929C64.7421 15.8667 63.6227 16.3121 62.7897 17.1503C61.9566 17.9886 61.5401 19.0364 61.6052 20.2021C61.6573 21.3417 62.1779 22.455 63.063 23.3457C63.9221 24.2101 65.0545 24.6816 66.2781 24.6816C67.5016 24.6816 68.647 24.2101 69.4931 23.3457C70.248 22.586 70.7036 21.5774 70.8077 20.5296C70.8988 19.508 71.6147 18.6566 72.604 18.3946C73.2027 20.6606 72.5519 23.0968 70.9119 24.7602C69.6883 26.0045 68.0483 26.6856 66.2911 26.6856Z" fill="#838383"/>
  <path d="M154.737 26.6856C152.98 26.6856 151.34 26.0045 150.103 24.7733C148.867 23.529 148.138 21.9442 148.06 20.3069C147.968 18.5911 148.593 16.967 149.83 15.7357C151.053 14.5045 152.68 13.8496 154.412 13.8889C156.104 13.9282 157.718 14.6355 158.941 15.8798C159.332 16.2728 159.332 16.9015 158.941 17.2944L156.546 19.7044C155.765 20.4903 154.503 20.4903 153.722 19.7044L156.741 16.6657C156.026 16.1942 155.192 15.9191 154.346 15.906C153.162 15.8798 152.056 16.3252 151.222 17.1634C149.543 18.8531 149.661 21.5119 151.496 23.3588C152.355 24.2232 153.487 24.6947 154.711 24.6947C155.934 24.6947 157.08 24.2232 157.926 23.3588C158.681 22.5991 159.136 21.5905 159.241 20.5427C159.332 19.5211 160.048 18.6697 161.037 18.4077C161.636 20.6737 160.985 23.1099 159.345 24.7733C158.134 26.0045 156.494 26.6856 154.737 26.6856Z" fill="#838383"/>
  <path d="M121.623 26.9476C121.493 26.9476 121.35 26.9476 121.207 26.9345C119.059 26.8297 116.99 25.8605 115.35 24.2232C113.736 22.5991 112.837 20.4117 112.837 18.0934C112.837 15.7751 113.723 13.5877 115.35 11.9636C117.523 9.77619 120.712 8.92483 123.68 9.7238V9.7369C123.407 10.7585 122.483 11.4396 121.441 11.4396C119.71 11.4396 118.018 12.1338 116.768 13.3912C115.532 14.6355 114.842 16.3121 114.842 18.1065C114.842 19.9009 115.532 21.5643 116.768 22.8217C118.044 24.1053 119.658 24.865 121.311 24.9567C122.977 25.0484 124.552 24.4197 125.75 23.2147C128.041 20.9094 128.184 17.3468 126.218 14.7403L123.159 17.8183C122.378 18.6042 121.116 18.6042 120.335 17.8183L125.554 12.5661C125.945 12.1731 126.57 12.1731 126.96 12.5661C128.587 14.2033 129.511 16.3383 129.563 18.578C129.616 20.8571 128.769 23.0182 127.155 24.6424C125.659 26.1224 123.693 26.9476 121.623 26.9476Z" fill="#838383"/>
  <path d="M86.5054 15.893H83.772C82.6656 15.893 81.7805 14.9892 81.7805 13.889H88.5099C88.4969 14.9892 87.6118 15.893 86.5054 15.893Z" fill="#838383"/>
  <path d="M133.026 11.361C133.579 11.361 134.028 10.9095 134.028 10.3525C134.028 9.79547 133.579 9.34393 133.026 9.34393C132.472 9.34393 132.023 9.79547 132.023 10.3525C132.023 10.9095 132.472 11.361 133.026 11.361Z" fill="#838383"/>
  <path d="M140.927 26.2272L135.928 14.3867C136.944 13.9545 138.115 14.426 138.545 15.4476L141.708 22.9266L144.871 15.4476C145.3 14.426 146.472 13.9545 147.487 14.3867L142.489 26.2272C142.189 26.9214 141.213 26.9214 140.927 26.2272Z" fill="#838383"/>
  </g>
  <g clip-path="url(#clip2)">
  <path d="M182.122 13.3239V10.7456C182.122 9.17618 180.855 7.86835 179.253 7.86835H175.639V16.1637H179.253C180.818 16.2011 182.122 14.8933 182.122 13.3239ZM177.129 9.36301H179.253C179.998 9.36301 180.632 9.96087 180.632 10.7456V13.3239C180.632 14.0712 180.035 14.7064 179.253 14.7064H177.129V9.36301Z" fill="#838383"/>
  <path d="M178.657 11.6424H178.806C179.327 11.6424 179.775 11.194 179.775 10.6708V10.1477H177.651V10.6335C177.651 11.194 178.098 11.6424 178.657 11.6424Z" fill="#838383"/>
  <path d="M188.68 18.032H179.216C179.216 17.322 178.619 16.7242 177.911 16.7242H177.725V18.032H168.894C168.894 18.8541 169.565 19.5267 170.385 19.5267H177.725V26.9626C178.545 26.9626 179.216 26.29 179.216 25.468V19.5267H187.19C188.009 19.5267 188.68 18.8541 188.68 18.032Z" fill="#838383"/>
  <path d="M171.726 6.3363L168.447 11.194C169.118 11.6424 170.049 11.4929 170.534 10.7829L172.136 8.39146C172.583 7.71887 172.397 6.7847 171.726 6.3363Z" fill="#838383"/>
  <path d="M174.707 15.5658C175.042 14.8185 174.893 13.9964 174.334 13.3986L173.515 12.5765H173.477L173.254 12.8007C172.807 13.2491 172.807 13.9591 173.254 14.4075C173.44 14.6317 173.366 14.8932 173.328 14.968C173.291 15.0801 173.142 15.3043 172.844 15.3043H170.832C170.795 15.3043 170.795 15.2669 170.795 15.2669L173.664 11.0445C174.111 10.3719 173.962 9.47507 173.291 9.02667H173.254L168 16.7615C168 16.7989 168 16.7989 168.037 16.7989H172.732C173.552 16.7989 174.372 16.3505 174.707 15.5658Z" fill="#838383"/>
  <path d="M185.736 6.3363L182.457 11.194C183.128 11.6424 184.06 11.4929 184.544 10.7829L186.146 8.39146C186.631 7.71887 186.444 6.7847 185.736 6.3363Z" fill="#838383"/>
  <path d="M186.78 16.7989C187.599 16.7989 188.382 16.3505 188.717 15.6032C189.052 14.8559 188.903 13.9964 188.382 13.3986L187.525 12.5391L187.264 12.8007C186.817 13.2491 186.817 13.9591 187.264 14.4075C187.45 14.6317 187.376 14.8932 187.338 14.968C187.301 15.0801 187.152 15.3043 186.854 15.3043H184.805L187.674 11.0818C188.121 10.4092 187.972 9.47508 187.264 8.98932L182.01 16.7989H186.78Z" fill="#838383"/>
  <path d="M168.261 25.3185C168.559 26.1032 169.453 26.4395 170.236 26.1032C172.844 24.8701 174.931 23.3007 176.123 22.2918C176.756 21.7687 176.831 20.7971 176.235 20.1993C176.197 20.2366 172.807 23.5249 168.261 25.3185Z" fill="#838383"/>
  <path d="M180.93 20.1993C180.333 20.7971 180.408 21.7687 181.041 22.2918C182.234 23.3007 184.32 24.8701 186.929 26.1032C187.674 26.4769 188.568 26.1032 188.903 25.3185C184.358 23.5249 180.967 20.2366 180.93 20.1993Z" fill="#838383"/>
  <path d="M209.583 17.3221C209.099 16.7242 208.428 16.3879 207.72 16.3879H198.517C199.076 15.678 199.448 15.1175 199.635 14.7812H204.851C206.491 14.7812 207.795 13.436 207.795 11.8292V9.17617C207.795 7.60678 206.528 6.29895 204.926 6.29895H195.201V14.7812H197.883C196.989 16.0516 195.014 18.3683 191.363 20.3114C191.773 21.0587 192.704 21.2829 193.412 20.8719C195.014 19.863 196.244 18.8541 197.213 17.9199H200.007C198.927 19.3399 196.728 21.7687 193.188 23.3007C193.524 24.0854 194.455 24.3844 195.163 24.0107C199.038 21.9182 201.162 18.9662 201.833 17.9199H204.479C203.659 19.5641 201.423 23.1513 196.318 25.5427C196.691 26.2901 197.585 26.589 198.331 26.178C203.584 23.226 205.596 19.1904 206.118 17.8826H207.758C208.093 17.8826 208.317 18.0694 208.466 18.2562C208.801 18.6673 208.95 19.2651 208.838 19.8256L208.428 21.9929C208.056 24.0107 206.677 25.4306 205.075 25.4306C205.075 26.3274 206.006 26.9253 206.826 26.5516C208.354 25.879 209.509 24.2723 209.881 22.2545L210.291 20.0872C210.478 19.1157 210.217 18.0694 209.583 17.3221ZM204.926 7.79361C205.671 7.79361 206.304 8.39148 206.304 9.17617V9.69931H196.691V7.79361H204.926ZM196.691 11.194H206.342V11.8292C206.342 12.6513 205.671 13.2865 204.888 13.2865H196.691V11.194Z" fill="#838383"/>
  <path d="M178.619 8.01779C179.175 8.01779 179.626 7.5661 179.626 7.0089C179.626 6.4517 179.175 6 178.619 6C178.064 6 177.613 6.4517 177.613 7.0089C177.613 7.5661 178.064 8.01779 178.619 8.01779Z" fill="url(#paint5_linear)"/>
  <path d="M232.238 9.81143V9.2883C232.238 7.7189 230.971 6.41107 229.369 6.41107H222.662V12.7634H229.257C230.897 12.7634 232.238 11.4556 232.238 9.81143ZM224.153 7.9431H229.369C230.114 7.9431 230.748 8.54096 230.748 9.32566V9.84879C230.748 10.6709 230.077 11.3061 229.295 11.3061H224.19V7.9431H224.153Z" fill="#838383"/>
  <path d="M217.669 6.29895H216.849V10.3719H213.943V11.194C213.943 11.5676 214.241 11.8666 214.614 11.8666H216.812V16.7616L213.943 18.4057L214.353 19.1157C214.539 19.452 214.949 19.5641 215.284 19.3772L216.812 18.5178V24.4217C216.812 24.7207 216.551 24.9822 216.253 24.9822H214.651C214.651 25.8043 215.322 26.4769 216.141 26.4769H216.253C217.371 26.4769 218.303 25.5427 218.303 24.4217V17.621L220.613 16.3132C220.948 16.1264 221.06 15.7153 220.874 15.379L220.464 14.6691L218.303 15.9022V11.8666H220.799C221.172 11.8666 221.47 11.5676 221.47 11.194V10.3719H218.303V7.00891C218.377 6.63525 218.042 6.29895 217.669 6.29895Z" fill="#838383"/>
  <path d="M230.152 13.9591H222.215V26.5516H223.258C223.519 26.5516 223.705 26.3648 223.705 26.1032V21.395H231.568V23.8612C231.568 24.7207 230.934 25.4306 230.189 25.4306H229.108V25.879C229.108 26.4395 229.593 26.9253 230.152 26.9253C231.754 26.9253 233.021 25.5427 233.021 23.8612V17.0232C233.058 15.3417 231.754 13.9591 230.152 13.9591ZM230.152 15.4538C230.785 15.4538 231.344 15.9395 231.493 16.6121H223.705V15.4538H230.152ZM223.705 19.9004V18.1068H231.568V19.9004H223.705Z" fill="#838383"/>
  </g>
  <defs>
  <linearGradient id="paint0_linear" x1="0.0434078" y1="15.5335" x2="29.2251" y2="15.5335" gradientUnits="userSpaceOnUse">
  <stop offset="0.00149187" stop-color="#FA8857"/>
  <stop offset="0.9976" stop-color="#FFBA2E"/>
  </linearGradient>
  <linearGradient id="paint1_linear" x1="17.5084" y1="7.09064" x2="20.7079" y2="7.09064" gradientUnits="userSpaceOnUse">
  <stop offset="0.00149187" stop-color="#FA8857"/>
  <stop offset="0.9976" stop-color="#FFBA2E"/>
  </linearGradient>
  <linearGradient id="paint2_linear" x1="21.7211" y1="11.1495" x2="24.9205" y2="11.1495" gradientUnits="userSpaceOnUse">
  <stop offset="0.00149187" stop-color="#FA8857"/>
  <stop offset="0.9976" stop-color="#FFBA2E"/>
  </linearGradient>
  <linearGradient id="paint3_linear" x1="15.032" y1="24.7462" x2="29.1897" y2="24.7462" gradientUnits="userSpaceOnUse">
  <stop offset="0.00149187" stop-color="#FA8857"/>
  <stop offset="0.9976" stop-color="#FFBA2E"/>
  </linearGradient>
  <linearGradient id="paint4_linear" x1="14.5982" y1="26.9934" x2="14.9848" y2="26.9934" gradientUnits="userSpaceOnUse">
  <stop offset="0.00149187" stop-color="#FA8857"/>
  <stop offset="0.9976" stop-color="#FFBA2E"/>
  </linearGradient>
  <linearGradient id="paint5_linear" x1="177.616" y1="7.01135" x2="179.628" y2="7.01135" gradientUnits="userSpaceOnUse">
  <stop offset="0.00149187" stop-color="#FA8857"/>
  <stop offset="0.9976" stop-color="#FFBA2E"/>
  </linearGradient>
  <clipPath id="clip0">
  <rect width="163" height="23" fill="white" transform="translate(0 4)"/>
  </clipPath>
  <clipPath id="clip1">
  <rect width="29.1684" height="23" fill="white" transform="translate(0 4)"/>
  </clipPath>
  <clipPath id="clip2">
  <rect width="65.058" height="21" fill="white" transform="translate(168 6)"/>
  </clipPath>
  </defs>
  </svg>
  </g>
`]
