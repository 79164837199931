/* ============
 * Vue core ui
 * ============
 *
 * UI plugin of Vue.js.
 *
 * https://coreui.io/vue/docs/introduction/
 */
import Vue from 'vue';
// Installing whole package
import CoreuiVue from '@coreui/vue';
Vue.use(CoreuiVue);