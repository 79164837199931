import Vue from 'vue';

function isAuth() {
  if (Vue.ls.get('token', null) != null) {
    return true;
  } else {
    return false;
  }
}

function loadAccount() {
  console.log("utils load account");
  //return [Vue.ls.get('ubn',null),Vue.ls.get('email',null)];
  // let ubn = Vue.ls.get('ubn', null);
  let email = Vue.ls.get('email', null);
  return {
    // ubn: ubn,
    email: email
  };
  // return {
  //   ubn: Vue.ls.get('ubn',null),
  //   email: Vue.ls.get('email',null)
  // };
}

function rememberAccount(user) {
  // Vue.ls.set('ubn', user.ubn);
  Vue.ls.set('email', user.email);
}

function clearAccount() {
  // Vue.ls.remove('ubn');
  Vue.ls.remove('email');
}

function login(user) {
  Vue.ls.set('token', user.access_token, 24 * 60 * 60 * 1000);
  Vue.ls.set('refresh_token', user.refresh_token, 24 * 60 * 60 * 1000);
  Vue.ls.set('roles', user.roles, 24 * 60 * 60 * 1000);
}

function logOut() {
  Vue.ls.remove('token');
  Vue.ls.remove('refresh_token');
  Vue.ls.remove('roles');
  Vue.router.replace({
    name: "Login"
  })
}

/** 此帳號否有此權限 */
function hasRole(rolesList) {
  let roles = Vue.ls.get('roles', null);
  if (rolesList == undefined) return true
  return rolesList.find(role => {
    return roles.includes(role);
  }) !== undefined
}

/** 
 * 確認 是否來自 指定頁path 
 * 
 * @param specifiedPathList 指定頁面path list
 * @return 上一頁(PreviousPageUrl) 與 指定頁面path -> 相同為true，反之false
 * 備註：sessionStorage.setItem("PreviousPageUrl") 實作 permission.js 檔案內
*/
function checkIsFromSpecifiedPath(specifiedPathList) {
  let previousPageUrl = sessionStorage.getItem("PreviousPageUrl");
  // return previousPageUrl === specifiedPath;
  if (specifiedPathList == undefined) 
    return false;
  else 
    return specifiedPathList.includes(previousPageUrl);
}

export default {
  isAuth,
  loadAccount,
  rememberAccount,
  clearAccount,
  login,
  logOut,
  hasRole,
  checkIsFromSpecifiedPath,
}
