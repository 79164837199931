import 'core-js/stable'
import Vue from 'vue'
import App from './App'
// import router from './router'
// import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'

//===========================//
import {
  router
} from './plugins/vue-router';
import './plugins/vuex';
import './plugins/axios';
import './plugins/vue-ls';
import './plugins/core-ui';
import './plugins/element-ui';
import './plugins/font-awesome';
import './plugins/vue-clipboard2';
//===========================//
// 路由設定
import '@/permission';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import "luciditv-vue-tel-input/dist/luciditv-vue-tel-input.css"
Vue.use(CKEditor);


Vue.config.performance = true
// Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

new Vue({
  router,
  store,
  icons,
  render: h => h(App)
}).$mount('#app')

// new Vue({
//   el: '#app',
//   router,
//   store,
//   icons,
//   template: '<App/>',
//   components: {
//     App
//   }
// })
