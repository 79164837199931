export const self_account_logout = ['20 20', `
<g>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path fill="#626262" d="M77.155 272.034H351.75v-32.001H77.155l75.053-75.053v-.001l-22.628-22.626l-113.681 113.68l.001.001h-.001L129.58 369.715l22.628-22.627v-.001l-75.053-75.053z"/><path fill="#626262" d="M160 16v32h304v416H160v32h336V16H160z"/></svg>
</g>` ]

export const self_dashboard = ['24 24', `
<g>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.12 9.16367V0.480469H20.16V9.16367C18.8002 9.39311 17.76 10.5758 17.76 12.0005C17.76 13.4251 18.8002 14.6073 20.16 14.8373V23.5205H21.12V14.8373C22.4798 14.6078 23.52 13.4251 23.52 12.0005C23.52 10.5758 22.4798 9.39359 21.12 9.16367ZM20.64 13.9205C19.5811 13.9205 18.72 13.0593 18.72 12.0005C18.72 10.9416 19.5811 10.0805 20.64 10.0805C21.6989 10.0805 22.56 10.9416 22.56 12.0005C22.56 13.0593 21.6989 13.9205 20.64 13.9205Z" fill="#9AA1AE"/>
<path d="M2.87998 0.480469V3.88367C1.52014 4.11359 0.47998 5.29631 0.47998 6.72047C0.47998 8.14463 1.52014 9.32735 2.87998 9.55727V23.5205H3.83998V9.55727C5.19982 9.32783 6.23998 8.14511 6.23998 6.72047C6.23998 5.29583 5.19982 4.11359 3.83998 3.88367V0.480469H2.87998ZM5.27998 6.72047C5.27998 7.77935 4.41886 8.64047 3.35998 8.64047C2.3011 8.64047 1.43998 7.77935 1.43998 6.72047C1.43998 5.66159 2.3011 4.80047 3.35998 4.80047C4.41886 4.80047 5.27998 5.66159 5.27998 6.72047Z" fill="#9AA1AE"/>
<path d="M11.52 0.480469V14.9237C10.1602 15.1531 9.12 16.3358 9.12 17.7605C9.12 19.1851 10.1602 20.3673 11.52 20.5973V23.5205H12.48V20.5973C13.8398 20.3678 14.88 19.1851 14.88 17.7605C14.88 16.3358 13.8398 15.1536 12.48 14.9237V0.480469H11.52ZM13.92 17.7605C13.92 18.8193 13.0589 19.6805 12 19.6805C10.9411 19.6805 10.08 18.8193 10.08 17.7605C10.08 16.7016 10.9411 15.8405 12 15.8405C13.0589 15.8405 13.92 16.7016 13.92 17.7605Z" fill="#9AA1AE"/>
</svg>
</g>
`]

export const self_list_rich = ['24 24', `
<g>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.375 1.125H0.75V6.75H6.375V1.125ZM4.875 5.25H2.25V2.625H4.875V5.25Z" fill="#9AA1AE"/>
<path d="M6.375 9.375H0.75V15H6.375V9.375ZM4.875 13.5H2.25V10.875H4.875V13.5Z" fill="#9AA1AE"/>
<path d="M6.375 17.625H0.75V23.25H6.375V17.625ZM4.875 21.75H2.25V19.125H4.875V21.75Z" fill="#9AA1AE"/>
<path d="M23.25 1.125H8.25V2.625H23.25V1.125Z" fill="#9AA1AE"/>
<path d="M20.25 5.25H8.25V6.75H20.25V5.25Z" fill="#9AA1AE"/>
<path d="M23.25 9.375H8.25V10.875H23.25V9.375Z" fill="#9AA1AE"/>
<path d="M20.25 13.5H8.25V15H20.25V13.5Z" fill="#9AA1AE"/>
<path d="M20.25 21.75H8.25V23.25H20.25V21.75Z" fill="#9AA1AE"/>
<path d="M23.25 17.625H8.25V19.125H23.25V17.625Z" fill="#9AA1AE"/>
</svg>
</g>` ]

export const self_user = ['61 48', `
<g>
<svg width="61" height="48" viewBox="0 0 61 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M31.3702 47.221H60.2772C60.2772 42.1209 56.1165 37.9923 50.9769 37.9923H49.3453C52.5541 33.8637 54.3217 28.8715 54.3217 23.6095C50.9497 23.6095 47.9856 25.7683 46.925 28.9525C46.082 31.462 44.6679 33.7827 42.71 35.7256C39.4195 38.9907 35.0957 40.7717 30.5272 40.7717C25.9586 40.7717 21.6076 38.9907 18.3444 35.7256C15.0811 32.4605 13.2591 28.1699 13.2591 23.6365C13.2591 19.1031 15.0539 14.7856 18.3444 11.5474C20.3023 9.60453 22.641 8.17434 25.17 7.36481C28.3789 6.31241 30.5544 3.37109 30.5544 0.0250003C24.1638 -0.00198425 18.2084 2.45361 13.667 6.93305C9.12568 11.4125 6.67824 17.3491 6.67824 23.6095C6.67824 28.8715 8.41864 33.8637 11.6547 37.9923H10.0503C4.91065 37.9923 0.75 42.1209 0.75 47.221H29.6026C29.9017 47.221 30.1737 47.248 30.4728 47.248C30.7719 47.248 31.0711 47.248 31.3702 47.221Z" fill="#919191"/>
</svg>
</g>` ]

export const self_relation = ['24 24', `
<g>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 0.75C5.78681 0.75 0.75 5.78681 0.75 12C0.75 18.2132 5.78681 23.25 12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.78681 18.2132 0.75 12 0.75ZM18.8943 18.8943C17.1848 20.5987 14.9019 21.6056 12.4906 21.7188C10.0792 21.832 7.7121 21.0434 5.85044 19.5066C3.98877 17.9698 2.76594 15.795 2.4202 13.4059C2.07446 11.0168 2.63058 8.5845 3.98021 6.58302C5.32984 4.58153 7.37629 3.15421 9.72081 2.57917C12.0653 2.00412 14.54 2.32255 16.6625 3.4724C18.7851 4.62226 20.4035 6.52117 21.2025 8.79912C22.0014 11.0771 21.9237 13.5709 20.9844 15.7947C20.4948 16.9534 19.785 18.0061 18.8943 18.8943Z" fill="#9AA1AE"/>
<path d="M7.875 9H6V10.875H7.875V9Z" fill="#9AA1AE"/>
<path d="M17.625 9H15.75V10.875H17.625V9Z" fill="#9AA1AE"/>
<path d="M10.875 14.3882C10.875 14.3662 10.8781 14.3443 10.8841 14.3232L12.375 9.10505V7.875H10.875V8.89495L9.44184 13.911C9.36798 14.1695 9.35513 14.4416 9.4043 14.7059C9.45348 14.9702 9.56333 15.2194 9.72521 15.4341C9.8871 15.6487 10.0966 15.8228 10.3372 15.9427C10.5778 16.0626 10.843 16.125 11.1118 16.125H13.875V14.625H11.1118C11.049 14.6249 10.9888 14.6 10.9444 14.5556C10.9 14.5112 10.8751 14.451 10.875 14.3882Z" fill="#9AA1AE"/>
</svg>
</g>` ]

export const  self_accountManage = ['24 24', `
<g>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.1164 14.8265L19.1003 12.8531L20.2616 10.7241C20.5417 10.1925 20.6884 9.60081 20.6892 8.99994V5.62494C20.6915 4.67596 20.4167 3.74695 19.8984 2.952C19.3801 2.15705 18.6409 1.53076 17.7717 1.15003C16.9024 0.769303 15.9409 0.650715 15.0052 0.808828C14.0695 0.966942 13.2003 1.39487 12.5044 2.04008L13.5238 3.14056C14.006 2.69472 14.6078 2.39927 15.2555 2.29044C15.9031 2.1816 16.5685 2.26412 17.1699 2.52786C17.7714 2.79161 18.2828 3.22512 18.6414 3.77525C19.0001 4.32537 19.1905 4.96821 19.1892 5.62494V8.99994C19.189 9.34982 19.1052 9.69459 18.9447 10.0055L17.1205 13.3499L21.2951 16.0815C21.5707 16.2619 21.7968 16.5082 21.953 16.7982C22.1091 17.0882 22.1903 17.4126 22.1892 17.7419V20.2499H18.8142V21.7499H23.6892V17.7418C23.6907 17.1633 23.5476 16.5936 23.2729 16.0845C22.9983 15.5753 22.6007 15.1429 22.1164 14.8265V14.8265Z" fill="#9AA1AE"/>
<path d="M14.9316 16.3265L11.9155 14.3531L13.0768 12.2241C13.3569 11.6925 13.5036 11.1008 13.5044 10.4999V7.12493C13.5034 5.83129 12.9893 4.59085 12.0749 3.67576C11.1606 2.76066 9.92052 2.24563 8.62687 2.24365C5.94014 2.24365 3.75436 4.43342 3.75436 7.12493V10.4999C3.7515 11.1005 3.89663 11.6926 4.17694 12.2238L5.3445 14.3643L2.34595 16.3265C1.86221 16.6424 1.46501 17.0741 1.1904 17.5825C0.915794 18.0908 0.772471 18.6597 0.773438 19.2374L0.75 23.2499H16.5044V19.2418C16.5059 18.6633 16.3628 18.0936 16.0881 17.5845C15.8135 17.0753 15.4159 16.6429 14.9316 16.3265V16.3265ZM15.0044 21.7499H2.25872L2.27325 19.2418C2.27212 18.9125 2.35331 18.5881 2.50945 18.2981C2.66559 18.0082 2.89172 17.7618 3.1673 17.5815L7.32427 14.8614L5.49375 11.5055C5.33506 11.1941 5.25299 10.8494 5.25436 10.4999V7.12493C5.25436 6.22983 5.60994 5.37138 6.24287 4.73845C6.87581 4.10551 7.73425 3.74993 8.62936 3.74993C9.52447 3.74993 10.3829 4.10551 11.0158 4.73845C11.6488 5.37138 12.0044 6.22983 12.0044 7.12493V10.4999C12.0042 10.8498 11.9204 11.1946 11.7599 11.5055L9.93572 14.8499L14.1103 17.5815C14.3859 17.7619 14.612 18.0082 14.7681 18.2982C14.9243 18.5882 15.0055 18.9126 15.0044 19.2419V21.7499Z" fill="#9AA1AE"/>
</svg>
</g>` ]

export const  self_groupTeamManage = ['24 24', `
<g>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.955 6.67007C18.3816 5.09672 16.3771 4.02526 14.1948 3.59117C12.0125 3.15709 9.75048 3.37987 7.69481 4.23136C5.63913 5.08285 3.88212 6.5248 2.64596 8.37486C1.40979 10.2249 0.749993 12.4 0.75 14.625V18.75H7.5V17.25H2.25V14.625C2.25 9.2489 6.62386 4.87504 12 4.87504C17.3761 4.87504 21.75 9.2489 21.75 14.625V17.25H16.5V18.75H23.25V14.625C23.254 13.1471 22.9649 11.683 22.3993 10.3175C21.8337 8.95204 21.0029 7.71231 19.955 6.67007Z" fill="#9AA1AE"/>
<path d="M5.25 12.375H3.75V13.875H5.25V12.375Z" fill="#9AA1AE"/>
<path d="M12.75 6H11.25V7.5H12.75V6Z" fill="#9AA1AE"/>
<path d="M7.875 7.875H6.375V9.375H7.875V7.875Z" fill="#9AA1AE"/>
<path d="M20.25 12.375H18.75V13.875H20.25V12.375Z" fill="#9AA1AE"/>
<path d="M13.9323 15.7078L17.176 8.94966L15.8237 8.30054L12.5807 15.0569C11.8629 14.9138 11.1176 15.0375 10.4845 15.4048C9.85136 15.7721 9.37397 16.3577 9.14182 17.0519C8.90966 17.746 8.93869 18.501 9.22346 19.1752C9.50822 19.8495 10.0292 20.3967 10.6886 20.7143C11.348 21.0319 12.1007 21.098 12.8054 20.9003C13.5101 20.7026 14.1185 20.2546 14.5165 19.6403C14.9145 19.026 15.0747 18.2877 14.9672 17.5637C14.8596 16.8397 14.4916 16.1798 13.9323 15.7078ZM12 19.5C11.7033 19.5 11.4133 19.412 11.1666 19.2472C10.92 19.0823 10.7277 18.8481 10.6142 18.574C10.5007 18.2999 10.4709 17.9983 10.5288 17.7073C10.5867 17.4163 10.7296 17.1491 10.9393 16.9393C11.1491 16.7295 11.4164 16.5867 11.7074 16.5288C11.9983 16.4709 12.2999 16.5006 12.574 16.6141C12.8481 16.7277 13.0824 16.9199 13.2472 17.1666C13.412 17.4133 13.5 17.7033 13.5 18C13.4996 18.3976 13.3414 18.7789 13.0602 19.0601C12.779 19.3413 12.3977 19.4995 12 19.5Z" fill="#9AA1AE"/>
</svg>
</g>` ]

export const  self_plan = ['24 24', `
<g>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.67798 11.8985L6.13995 13.9272L6.43338 14.5964L6.04376 17.8128L9.21916 17.5065L9.89979 17.7722L11.8986 20.3222L13.9274 17.8602L14.5965 17.5668L17.8129 17.9565L17.5066 14.781L17.7722 14.1004L20.3222 12.1016L17.8602 10.0729L17.5668 9.40371L17.9564 6.18724L14.7811 6.49362L14.1004 6.22798L12.1016 3.67798L10.0729 6.13995L9.40366 6.43338L6.18729 6.04376L6.49366 9.21907L6.22793 9.89979L3.67798 11.8985ZM7.47607 10.8273L8.02104 9.43127L7.85951 7.75741L9.6304 7.97191L11.0029 7.37008L12.0723 6.07226L13.1729 7.47621L14.5688 8.02094L16.2427 7.85946L16.0282 9.63039L16.63 11.0029L17.9278 12.0723L16.5239 13.1729L15.9791 14.5688L16.1407 16.2428L14.3697 16.0282L12.9973 16.63L11.9278 17.9279L10.8273 16.524L9.43132 15.9791L7.75737 16.1406L7.97191 14.3696L7.37009 12.9971L6.07226 11.9277L7.47607 10.8273Z" fill="#9AA1AE"/>
<path d="M22.125 0.75H1.875C1.57674 0.750347 1.29079 0.868985 1.07989 1.07989C0.868985 1.29079 0.750347 1.57674 0.75 1.875V22.125C0.750347 22.4233 0.868985 22.7092 1.07989 22.9201C1.29079 23.131 1.57674 23.2497 1.875 23.25H22.125C22.4233 23.2497 22.7092 23.131 22.9201 22.9201C23.131 22.7092 23.2497 22.4233 23.25 22.125V1.875C23.2497 1.57674 23.131 1.29079 22.9201 1.07989C22.7092 0.868985 22.4233 0.750347 22.125 0.75ZM21.75 21.75H2.25V2.25H21.75V21.75Z" fill="#9AA1AE"/>
</svg>
</g>` ]

