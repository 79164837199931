export const logoThumbnail = ['31 23', `
 <g>
 <svg width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
 <g clip-path="url(#clip0)">
 <path d="M15.0108 22.9859H29.1817C29.1817 20.5033 27.1421 18.4936 24.6225 18.4936H23.8226C25.3957 16.4839 26.2622 14.0539 26.2622 11.4925C24.6092 11.4925 23.1561 12.5433 22.6362 14.0933C22.2229 15.3149 21.5297 16.4445 20.5699 17.3903C18.9568 18.9796 16.8372 19.8466 14.5975 19.8466C12.3579 19.8466 10.2249 18.9796 8.62521 17.3903C7.02548 15.8009 6.1323 13.7124 6.1323 11.5056C6.1323 9.29888 7.01215 7.19723 8.62521 5.62099C9.58505 4.67524 10.7315 3.97907 11.9713 3.58501C13.5444 3.07273 14.6109 1.64098 14.6109 0.0121921C11.4781 -0.000943271 8.55856 1.19437 6.33226 3.37484C4.10597 5.55531 2.90618 8.44509 2.90618 11.4925C2.90618 14.0539 3.75937 16.4839 5.34576 18.4936H4.55923C2.03966 18.4936 0 20.5033 0 22.9859H14.1443C14.2909 22.9859 14.4242 22.9991 14.5709 22.9991C14.7175 22.9991 14.8642 22.9991 15.0108 22.9859Z" fill="url(#paint10_linear)"/>
 <path d="M19.1034 4.66304C19.9869 4.66304 20.7031 3.95734 20.7031 3.0868C20.7031 2.21627 19.9869 1.51056 19.1034 1.51056C18.2199 1.51056 17.5037 2.21627 17.5037 3.0868C17.5037 3.95734 18.2199 4.66304 19.1034 4.66304Z" fill="url(#paint11_linear)"/>
 <path d="M23.316 8.72188C24.1995 8.72188 24.9158 8.01617 24.9158 7.14564C24.9158 6.2751 24.1995 5.5694 23.316 5.5694C22.4325 5.5694 21.7163 6.2751 21.7163 7.14564C21.7163 8.01617 22.4325 8.72188 23.316 8.72188Z" fill="url(#paint12_linear)"/>
 <path d="M23.8228 18.4946C23.5162 18.8886 23.183 19.2564 22.823 19.6111C20.7167 21.6865 17.9572 22.8818 15.011 22.9869H29.1686C29.1686 20.5043 27.129 18.4946 24.6094 18.4946H23.8228Z" fill="url(#paint13_linear)"/>
 <path d="M14.5977 23C14.731 23 14.8509 23 14.9843 22.9868C14.8509 23 14.731 23 14.5977 23Z" fill="url(#paint14_linear)"/>
 </g>
 <defs>
 <linearGradient id="paint10_linear" x1="0.0434078" y1="11.5335" x2="29.2251" y2="11.5335" gradientUnits="userSpaceOnUse">
 <stop offset="0.00149187" stop-color="#FA8857"/>
 <stop offset="0.9976" stop-color="#FFBA2E"/>
 </linearGradient>
 <linearGradient id="paint11_linear" x1="17.5084" y1="3.09064" x2="20.7079" y2="3.09064" gradientUnits="userSpaceOnUse">
 <stop offset="0.00149187" stop-color="#FA8857"/>
 <stop offset="0.9976" stop-color="#FFBA2E"/>
 </linearGradient>
 <linearGradient id="paint12_linear" x1="21.7211" y1="7.14947" x2="24.9205" y2="7.14947" gradientUnits="userSpaceOnUse">
 <stop offset="0.00149187" stop-color="#FA8857"/>
 <stop offset="0.9976" stop-color="#FFBA2E"/>
 </linearGradient>
 <linearGradient id="paint13_linear" x1="15.032" y1="20.7462" x2="29.1897" y2="20.7462" gradientUnits="userSpaceOnUse">
 <stop offset="0.00149187" stop-color="#FA8857"/>
 <stop offset="0.9976" stop-color="#FFBA2E"/>
 </linearGradient>
 <linearGradient id="paint14_linear" x1="14.5982" y1="22.9934" x2="14.9848" y2="22.9934" gradientUnits="userSpaceOnUse">
 <stop offset="0.00149187" stop-color="#FA8857"/>
 <stop offset="0.9976" stop-color="#FFBA2E"/>
 </linearGradient>
 <clipPath id="clip0">
 <rect width="29.1684" height="23" fill="white"/>
 </clipPath>
 </defs>
 </svg>
 
 </g>
`]
