// import Vue from 'vue'
// import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

const CustomerManage = () => import('@/views/Customer/CustomerManage')
const CustomerStoreManage = () => import('@/views/Customer/CustomerStoreManage')
const CustomerEdit = () => import('@/views/Customer/CustomerEdit')
const RelationManage = () => import('@/views/Relation/RelationManage')
const AccountManage = () => import('@/views/Account/AccountManage')
const GroupTeamManage = () => import('@/views/GroupTeam/GroupTeamManage')
const GroupTeamAdd = () => import('@/views/GroupTeam/GroupTeamAdd')
const GroupTeamEdit = () => import('@/views/GroupTeam/GroupTeamEdit')
const PlanManage = () => import('@/views/Plan/PlanManage')

// Views - Pages
const Dashboard = () => import('@/views/Dashboard')
const Page404 = () => import('@/views/common/Page404')
const Page500 = () => import('@/views/common/Page500')
const Login = () => import('@/views/common/Login')
const Register = () => import('@/views/common/Register')

export default [
  {
    path: '/',
    redirect: '/dashboard',
    name: '首頁',
    component: TheContainer,
    meta: {
      auth: true
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          breadcrumb: [
            {
              name: '首頁',
              link: '/'
            },
            {
              name: 'Dashboard'
            },
          ]
        },
      },
      {
        path: 'customerManage',
        name: '用戶管理',
        component: CustomerManage,
        meta: {
          role:['ROLE_SVC_CUSTOMER_EDITABLE', 'ROLE_SVC_CUSTOMER_EXPORTABLE', 'ROLE_SVC_CUSTOMER_READABLE'],
          breadcrumb: [
            {
              name: '首頁',
              link: '/'
            },
            {
              name: '用戶管理'
            },
          ]
        },
      },

      {
        path: 'customerManage/store',
        name: '組織商店',
        component: CustomerStoreManage,
        meta: {
          role:['ROLE_SVC_CUSTOMER_EDITABLE', 'ROLE_SVC_CUSTOMER_EXPORTABLE', 'ROLE_SVC_CUSTOMER_READABLE'],
          breadcrumb: [
            {
              name: '首頁',
              link: '/'
            },
            {
              name: '用戶管理',
              link: '/customerManage'
            },
            {
              name: '商店',
            },
          ]
        },
        props: true,
      },

      // {
      //   path: 'customerManage/add',
      //   name: '用戶管理-新增',
      //   component: CustomerEdit,
      //   meta: {
      //     role:['ROLE_SVC_CUSTOMER_EDITABLE', 'ROLE_SVC_CUSTOMER_EXPORTABLE', 'ROLE_SVC_CUSTOMER_READABLE'],
      //     breadcrumb: [
      //       {
      //         name: '首頁',
      //         link: '/'
      //       },
      //       {
      //         name: '用戶管理',
      //         link: '/customerManage'
      //       },
      //       {
      //         name: '新增',
      //       },
      //     ]
      //   },
      //   props: true,
      // },
      
      {
        path: 'customerManage/edit',
        name: '用戶管理-編輯',
        component: CustomerEdit,
        meta: {
          role:['ROLE_SVC_CUSTOMER_EDITABLE', 'ROLE_SVC_CUSTOMER_EXPORTABLE', 'ROLE_SVC_CUSTOMER_READABLE'],
          breadcrumb: [
            {
              name: '首頁',
              link: '/'
            },
            {
              name: '用戶管理',
              link: '/customerManage'
            },
            {
              name: '編輯',
            },
          ]
        },
        props: true,
      },

      {
        path: 'relationManage',
        name: '關係管理',
        component: RelationManage,
        meta: {
          role:['ROLE_SVC_RELATION_EDITABLE', 'ROLE_SVC_RELATION_EXPORTABLE', 'ROLE_SVC_RELATION_READABLE'],
          breadcrumb: [
            {
              name: '首頁',
              link: '/'
            },
            {
              name: '關係管理'
            },
          ]
        },
      },

      {
        path: 'accountManage',
        name: '帳號管理',
        component: AccountManage,
        meta: {
          role: ['ROLE_SVC_USER_EDITABLE', 'ROLE_SVC_USER_EXPORTABLE', 'ROLE_SVC_USER_READABLE'],
          breadcrumb: [
            {
              name: '首頁',
              link: '/'
            },
            {
              name: '權限管理'
            },
            {
              name: '帳號管理'
            },
          ]
        },
      },

      {
        path: 'groupTeamManage',
        name: '權限管理',
        component: GroupTeamManage,
        meta: {
          role: ['ROLE_SVC_USERGROUP_EDITABLE', 'ROLE_SVC_USERGROUP_EXPORTABLE', 'ROLE_SVC_USERGROUP_READABLE'],
          breadcrumb: [
            {
              name: '首頁',
              link: '/'
            },
            {
              name: '權限管理'
            },
            {
              name: '權限管理'
            },
          ]
        },
      },

      {
        path: 'groupTeamManage/add',
        name: '權限管理-新增',
        component: GroupTeamAdd,
        meta: {
          role:['ROLE_SVC_USERGROUP_EDITABLE', 'ROLE_SVC_USERGROUP_EXPORTABLE', 'ROLE_SVC_USERGROUP_READABLE'],
          breadcrumb: [
            {
              name: '首頁',
              link: '/'
            },
            {
              name: '權限'
            },
            {
              name: '權限管理',
              link: '/groupTeamManage'
            },
            {
              name: '新增',
            },
          ]
        },
        props: true,
      },
      
      {
        path: 'groupTeamManage/edit',
        name: '權限管理-編輯',
        component: GroupTeamEdit,
        meta: {
          role:['ROLE_SVC_USERGROUP_EDITABLE', 'ROLE_SVC_USERGROUP_EXPORTABLE', 'ROLE_SVC_USERGROUP_READABLE'],
          breadcrumb: [
            {
              name: '首頁',
              link: '/'
            },
            {
              name: '權限'
            },
            {
              name: '權限管理',
              link: '/groupTeamManage'
            },
            {
              name: '編輯',
            },
          ]
        },
        props: true,
      },

      {
        path: 'planManage',
        name: '方案管理',
        component: PlanManage,
        meta: {
          role: ['ROLE_SVC_PLAN_EDITABLE', 'ROLE_SVC_PLAN_EXPORTABLE', 'ROLE_SVC_PLAN_READABLE'],
          breadcrumb: [
            {
              name: '首頁',
              link: '/'
            },
            {
              name: '樂易捐方案管理'
            },
            {
              name: '方案管理'
            },
          ]
        },
      },
      
      
      
    ]
  },
  {
    path: '/common',
    redirect: '/common/404',
    name: 'Common',
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: Page404
      },
      {
        path: '500',
        name: 'Page500',
        component: Page500
      },
      {
        path: 'login',
        name: 'Login',
        component: Login
      },
      {
        path: 'register',
        name: 'Register',
        component: Register
      }
    ]
  }
]

// Vue.use(Router)

// export default new Router({
//   mode: 'hash', // https://router.vuejs.org/api/#mode
//   linkActiveClass: 'active',
//   scrollBehavior: () => ({ y: 0 }),
//   routes: configRoutes()
// })

// function configRoutes () {
//   return [
//     {
//       path: '/',
//       name: 'Home',
//       component: TheContainer,
//       children: [
//       ]
//     },
//     {
//       path: '/pages',
//       redirect: '/pages/404',
//       name: 'Pages',
//       component: {
//         render (c) { return c('router-view') }
//       },
//       children: [
//         {
//           path: '404',
//           name: 'Page404',
//           component: Page404
//         },
//         {
//           path: '500',
//           name: 'Page500',
//           component: Page500
//         },
//         {
//           path: 'login',
//           name: 'Login',
//           component: Login
//         },
//         {
//           path: 'register',
//           name: 'Register',
//           component: Register
//         }
//       ]
//     }
//   ]
// }

